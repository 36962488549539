import React from 'react';
import { gimmeOne } from '../utilities/utils.js';

export default function TalkAboutIt() {
  const topics = [
    'Dating',
    'Holidays',
    'Sports',
    'Disease',
    'Famous People',

    'Fashion',
    'Design',
    'Summer',
    'Winter',
    'Fall',

    'Spring',
    'Plants',
    'Exercise',
    'Music',
    'Movies',

    'Writing',
    'Reading',
    'Painting',
    'Drawing',
    'Sewing',

    'Cars',
    'Travel',
    'Politics',
    'Shoes',
    'Snakes',

    'Dance',
    'Meats',
    'Sharks',
    'Asia',
    'South America',

    'Europe',
    'America',
    'Canada',
    'Ontario',
    'Sheep',

    'Swamps',
    'Houses',
    'Jewelery',
    'Bears',
    'War',

    'Medicine',
    'Crime',
    'Caribean',
    'Tanning',
    'Railroads',

    'Royalty',
    'Democracy',
    'Creativity',
    'Death',
    'Living your best life',

    'Magic',
    'Chores',
    'Amphibians',
    'Pets',
    'Feelings',

    'Prisons',
    'Women',
    'Men',
    'Rice',
    'Bread',

    'Potatoes',
    'Party food',
    'Picnics',
    'Roads',
    'Weapons',

    'Science',
    'Faith',
    'Water',
    'Air',
    'Ground/Soil',

    'Ice Cream',
    'Fire',
    'Forests',
    'Funerals',
    'Cycling',

    'Money',
    'Walls',
    'Horses',
    'Veagan/Vegitarian',
    'Banking',

    'News',
    'Holes',
    'Clowns',
    'Circus',
    'Public Transit',

    'Environment',
    'Climate Change',
    'Farming',
    'Making things',
    'Swimming',

    'Sex',
    'Olympics',
    'Drugs',
    'Friends',
    'Happiness',

    'Mothers',
    'Fathers',
    'Nurses',
    'Doctors',
    'Star Wars',
  ];

  return (
    <div style={{ width: `${600}px`, marginLeft: 'auto', marginRight: 'auto' }}>
      <h1 style={{ textAlign: 'center' }}>
        Choose from the following:
        <ul>
          <li>
            Talk about the following in the structure of What, So What, Now what
          </li>
          <li>
            Talk about the following in the structure of Problem/Opportunity,
            Solution, Benefit
          </li>
          <li>
            Set a timer for 60 seconds and say out loud every word you can think
            of related to....
          </li>
        </ul>
        <br />
        <br />
        {gimmeOne(topics)}
      </h1>
    </div>
  );
}
